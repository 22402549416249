import { isServer } from 'solid-js/web';
import { createSignal, onCleanup, onMount } from 'solid-js';
import { captureException, captureMessage } from '@sentry/solidstart';
import { useBeforeLeave } from '@solidjs/router';
import { revalidate } from '../graphql/cache';
import { getConfigValue } from '../modules/config';
import type { ParentProps } from 'solid-js';
import type { CacheKey } from '../graphql/cache';

type Props = ParentProps<{
	keys: CacheKey | Array<CacheKey> | void;
	timeout?: number;
}>;

const fourMinutes = 60_000 * 4;
const periodicTimeoutMs = 600_000;
let hiddenAt: number = -1;

export function Revalidator(props: Props) {
	const listener = async () => {
		if (document.visibilityState === 'hidden') {
			hiddenAt = Date.now();
			return;
		}

		if (document.visibilityState === 'visible' && hiddenAt > 0) {
			if (Date.now() - hiddenAt > (props.timeout || fourMinutes)) {
				await revalidate(props.keys);
			}
			hiddenAt = -1;
		}
	};

	onMount(() => {
		if (!isServer) {
			document.addEventListener('visibilitychange', listener);
		}
	});

	onCleanup(() => {
		if (!isServer) {
			document.removeEventListener('visibilitychange', listener);
		}
	});

	return props.children;
}

export async function isVersionMismatch() {
	if (isServer) {
		return false;
	}
	try {
		const res = await fetch(`https://${getConfigValue('HOST')}/version.json`);
		if (res.ok) {
			const deployVersion = (await res.json()).version;
			if (deployVersion !== import.meta.env.VITE_GIT_SHA) {
				captureMessage('Deploy version mismatch', {
					level: 'info',
					extra: { currentVersion: import.meta.env.VITE_GIT_SHA, deployVersion },
				});
			}
			return deployVersion !== import.meta.env.VITE_GIT_SHA;
		}
	} catch (e) {
		captureException(e);
	}
	return false;
}

export function createVisibilityReloader() {
	const [needsReload, setNeedsReload] = createSignal(false);
	let timeout: NodeJS.Timeout;

	const listener = async () => {
		if (document.visibilityState === 'visible') {
			const isMismatch = await isVersionMismatch();
			if (isMismatch) {
				window.location.reload();
			}
		}
	};

	async function periodicCheck() {
		clearTimeout(timeout);
		const isMismatch = await isVersionMismatch();
		if (isMismatch) {
			setNeedsReload(true);
		} else {
			timeout = setTimeout(periodicCheck, periodicTimeoutMs);
		}
	}

	useBeforeLeave((e) => {
		if (needsReload() && typeof e.to === 'string') {
			e.preventDefault();
			window.location.href = new URL(e.to, `https://${window.location.host}`).toString();
		}
	});

	onMount(() => {
		if (!isServer) {
			document.addEventListener('visibilitychange', listener, false);
			// Check immediately-ish
			timeout = setTimeout(periodicCheck, periodicTimeoutMs);
		}
	});

	onCleanup(() => {
		if (!isServer) {
			clearTimeout(timeout);
			document.removeEventListener('visibilitychange', listener, false);
		}
	});
}
